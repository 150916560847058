import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { ProductOptions } from './ProductOptions';
import './Gifts.scss';

export const Gifts = ({ gifts, onChoose }) => (
  <Row>
    {gifts.map(giftProduct => (
      <Col key={giftProduct.product._id} xs="12" md="6" lg="4" className="text-center mb-5 pb-5 d-flex flex-column">
        <img
          src={giftProduct.shopifyProduct.featured_image}
          alt={giftProduct.shopifyProduct.title}
          className="img-fluid gifts-product-image"
        />
        <h4 className="mb-4 mt-3">{giftProduct.shopifyProduct.title}</h4>
        <ProductOptions gift={giftProduct} onChoose={onChoose} />
      </Col>
    ))}
  </Row>
);

Gifts.propTypes = {
  gifts: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ _id: PropTypes.string.isRequired }),
    })
  ).isRequired,
  onChoose: PropTypes.func.isRequired,
};

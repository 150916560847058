import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Price, RadioBox } from '@bottomless/common/components';

export const ProductOption = ({ gift, shopifyVariant }) => {
  const isSoldOut = useMemo(() => !shopifyVariant?.available || (gift.limit && gift.purchaseCounter >= gift.limit), [
    gift,
    shopifyVariant,
  ]);

  return (
    <RadioBox
      name="gift"
      type="radio"
      value={gift._id}
      disabled={isSoldOut}
      label={
        <div className="d-flex flex-grow-1 align-items-center justify-content-between">
          <div className="text-lowercase">{gift.credits} months</div>
          <div className="d-flex align-items-center">
            {isSoldOut && <div className="text-secondary text-capitalize">Sold out</div>}
            {!isSoldOut && (
              <>
                {gift.originalPrice && (
                  <div className="text-strikethrough mr-2">
                    <Price value={gift.originalPrice} cents />
                  </div>
                )}
                <Price value={shopifyVariant.price} cents />
              </>
            )}
          </div>
        </div>
      }
    />
  );
};

ProductOption.propTypes = {
  gift: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    credits: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    originalPrice: PropTypes.number,
    limit: PropTypes.number,
    purchaseCounter: PropTypes.number,
  }).isRequired,
  shopifyVariant: PropTypes.shape({
    price: PropTypes.number.isRequired,
    available: PropTypes.bool.isRequired,
  }),
};

/* global hj */
import * as Sentry from '@sentry/browser';
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary, Loader, Toasts } from '@bottomless/common/components';
import { browserMatches } from '@bottomless/common/utils';
import { LogoutTypes } from '@bottomless/common/store';

import { createStore } from './store';
import { ConfigContextProvider } from './context/config.context';
import { GiftsRoutes } from './router/GiftsRoutes';

export class GiftsApp extends Component {
  constructor(props) {
    super(props);

    const { store, persistor } = createStore();
    this.store = store;
    this.persistor = persistor;
  }

  componentDidCatch(error, errorInfo) {
    const errorAlreadyExists = window.location.href.match(/error/);
    const isChunkLoadError = error.name === 'ChunkLoadError';
    const isFirstChunkError = isChunkLoadError && !errorAlreadyExists;

    if (process.env.NODE_ENV === 'production' && !isFirstChunkError) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);

        if ('hj' in window && hj.globals) scope.setExtra('hotjar_user_id', hj.globals.get('userId'));

        Sentry.captureException(error);
      });

      if ('hj' in window) {
        window.hj('tagRecording', ['Error']);
      }
    }

    if (!errorAlreadyExists) {
      if (!isChunkLoadError) {
        this.store.dispatch({ type: LogoutTypes.SUCCESS });
      }

      const sufix = window.location.href.match(/\?/) ? '&error' : '?error';
      window.location.replace(`${window.location.href}${sufix}`);
    } else if (!browserMatches()) {
      window.location.replace(`/update-browser.html`);
    }
  }

  render() {
    const { store, persistor } = this;
    const { config } = this.props;

    return (
      <CookiesProvider>
        <ConfigContextProvider value={{ config }}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<Loader />}>
                <Router hashType="noslash">
                  <ErrorBoundary>
                    <Switch>
                      <Route path="/" component={GiftsRoutes} />
                    </Switch>
                  </ErrorBoundary>
                </Router>
                <Toasts />
              </Suspense>
            </PersistGate>
          </Provider>
        </ConfigContextProvider>
      </CookiesProvider>
    );
  }
}

GiftsApp.propTypes = {
  config: PropTypes.object.isRequired,
};

import React from 'react';
import { Col, Row } from 'reactstrap';
import { datesService } from '@bottomless/common/utils';
import { Box, Field } from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';

export const RecipientInformation = () => (
  <PanelSection title="Enter recipient information">
    <Box>
      <Row>
        <Col xs="6">
          <Field name="recipient.first_name" label="First Name" />
        </Col>
        <Col xs="6">
          <Field name="recipient.last_name" label="Last Name" />
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <Field name="recipient.email" type="email" label="E-mail Address" />
        </Col>
        <Col xs="6">
          <Field name="recipient.phone" type="phone" label="Phone (Optional)" />
        </Col>
      </Row>
      <Field name="recipient.date" type="date" label="Gift date" min={datesService.getTodayInSeattle()} />
      <p className="small">A notification will be sent to the recipient on the gift date.</p>
      <Field
        name="info"
        type="textarea"
        component="textarea"
        rows="1"
        label="Leave a note for the recipient"
        className="gifts-purchase-note"
      />
    </Box>
  </PanelSection>
);

import React from 'react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export const GiftsDetails = ({ gift, shopifyProduct, shopifyVariant, quantity }) => {
  const variantTitle = useMemo(() => {
    const monthsOptionIndex = shopifyProduct.options.findIndex(option => option.toLowerCase().includes('month'));

    return shopifyVariant.options.filter((_, i) => i !== monthsOptionIndex).join(' / ');
  }, [shopifyProduct, shopifyVariant]);

  return (
    <div>
      <img src={shopifyProduct.featured_image} alt={shopifyProduct.title} className="img-fluid mb-3" />
      <div>
        <h5>
          {shopifyProduct.title}
          {quantity > 1 && <span className="ml-1 text-secondary">(x{quantity})</span>}
        </h5>
        <div className="text-secondary">{variantTitle}</div>
        <div>{gift.credits} months</div>
      </div>
    </div>
  );
};

GiftsDetails.propTypes = {
  gift: PropTypes.shape({
    credits: PropTypes.number.isRequired,
  }).isRequired,
  shopifyProduct: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featured_image: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shopifyVariant: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    public_title: PropTypes.string.isRequired,
  }).isRequired,
  quantity: PropTypes.number,
};

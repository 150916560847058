import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GiftsPage } from '../pages/Gifts';
import { GiftRedeemPage } from '../pages/GiftRedeem';

export const GiftsRoutes = () => (
  <Switch>
    <Route path="/redeem/:code" component={GiftRedeemPage} />
    <Route path="/" component={GiftsPage} />
  </Switch>
);

import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { groupBy } from 'lodash-es';
import { getGiftsAction, purchaseGiftInitAction } from '../../../store/gift';

export const useGifts = (selectedGiftId, selectedVariantId) => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getGiftsAction()), [dispatch]);

  const { data: rawData, error, isFetching: isLoading } = useDataEffect(action);

  const data = useMemo(
    () =>
      Object.values(groupBy(rawData || [], 'productId._id'))
        .map(gifts => ({
          name: gifts[0].name,
          product: gifts[0].productId,
          shopifyProduct: window.BottomlessGifts?.find(
            product => String(product?.id) === String(gifts[0].productId.original_id)
          ),
          gifts,
        }))
        .filter(gift => gift.shopifyProduct),
    [rawData]
  );

  const selectedGift = useMemo(() => (rawData || []).find(gift => gift._id === selectedGiftId), [
    rawData,
    selectedGiftId,
  ]);

  const selectedShopifyProduct = useMemo(
    () =>
      window.BottomlessGifts?.find(product =>
        product.variants?.find(variant => String(variant.id) === selectedVariantId)
      ),
    [selectedVariantId]
  );

  const selectedShopifyVariant = useMemo(
    () => selectedShopifyProduct?.variants.find(variant => String(variant.id) === selectedVariantId),
    [selectedShopifyProduct, selectedVariantId]
  );

  const purchaseGiftInit = useCallback(data => dispatch(purchaseGiftInitAction(data)), [dispatch]);

  return { data, isLoading, error, purchaseGiftInit, selectedGift, selectedShopifyProduct, selectedShopifyVariant };
};

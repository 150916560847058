import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Price } from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';
import './SelectedGift.scss';

export const SelectedGift = ({ gift, shopifyProduct, shopifyVariant, quantity }) => {
  const variantTitle = useMemo(() => {
    const monthsOptionIndex = shopifyProduct.options.findIndex(option => option.toLowerCase().includes('month'));

    return shopifyVariant.options.filter((_, i) => i !== monthsOptionIndex).join(' / ');
  }, [shopifyProduct, shopifyVariant]);

  return (
    <PanelSection title="Subscription details">
      <Box innerClassName="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={shopifyProduct.featured_image}
            alt={shopifyProduct.title}
            className="img-fluid mr-3 selected-gift-image"
          />
          <div>
            <div className="font-weight-bold">
              {shopifyProduct.title}
              {quantity > 1 && <span className="ml-1 text-secondary">(x{quantity})</span>}
            </div>
            <div className="text-secondary">{variantTitle}</div>
            <div>{gift.credits} months</div>
          </div>
        </div>
        <div>
          {gift.originalPrice && (
            <div className="text-strikethrough mr-2">
              <Price value={gift.originalPrice * quantity} cents />
            </div>
          )}
          <div className="font-weight-bold">
            <Price value={shopifyVariant.price * quantity} cents />
          </div>
        </div>
      </Box>
    </PanelSection>
  );
};

SelectedGift.propTypes = {
  shopifyProduct: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featured_image: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shopifyVariant: PropTypes.shape({
    public_title: PropTypes.string.isRequired,
    options: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  gift: PropTypes.shape({
    name: PropTypes.string.isRequired,
    credits: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    originalPrice: PropTypes.number,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
};

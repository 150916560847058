import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from '../../../hooks/use-local-storage.hook';
import { getGiftPurchaseAction, giftPurchaseGetStartedAction } from '../../../store/gift';
import { updateCheckoutAddressAction } from '../../../store/checkout';
import { getMeAction } from '../../../store/user';

export const useGiftPurchase = code => {
  const [, setLocalStorageValue] = useLocalStorage(['bottomless_access_token']);

  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getGiftPurchaseAction(code)), [code, dispatch]);

  const { data, error, isFetching: isLoading } = useDataEffect(action, undefined, undefined, null);

  const shopifyProduct = useMemo(
    () =>
      data &&
      (window.BottomlessRedeemGifts || window.BottomlessGifts)?.find(
        product => String(product.id) === String(data.giftId.productId.original_id)
      ),
    [data]
  );

  const shopifyVariant = useMemo(() => {
    if (!shopifyProduct) {
      return null;
    }

    const variant = data.giftId.productId.variants.find(variant => variant.original_id_mapping[data.grind], []);
    const shopifyVariantId =
      variant?.original_id_mapping[data.grind] ||
      data.giftId.productId.variants.find(v => v.available && v.original_id_mapping.default)?.original_id_mapping
        .default;

    return shopifyProduct.variants.find(v => String(v.id) === String(shopifyVariantId));
  }, [data, shopifyProduct]);

  const redeem = useCallback(
    async data => {
      const { payload } = await dispatch(giftPurchaseGetStartedAction({ code }));

      await dispatch(updateCheckoutAddressAction(payload.checkoutId, data));

      const res = await fetch(`${process.env.REACT_APP_BACKEND_DIRECT_URL}/api/checkouts/finish`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ checkout_id: payload.checkoutId, giftCode: code }),
        method: 'POST',
      });

      const result = await res.json();

      setLocalStorageValue('bottomless_access_token', result.accessToken);

      const { payload: me } = await dispatch(getMeAction());

      if (me.shopifyCustomerCreated && me.accounts.length < 2) {
        window.location.href = '/pages/subscription-by-usage#set-password';
      } else {
        window.location.href = '/pages/subscription-by-usage#profile';
      }
    },
    [dispatch, code, setLocalStorageValue]
  );

  return { data, isLoading, error, redeem, shopifyProduct, shopifyVariant };
};

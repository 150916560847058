import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import { datesService } from '@bottomless/common/utils';
import { Field, Form, SubmitButton } from '@bottomless/common/components';
import { useShopifyCartAdd } from '../../../hooks/use-shopify-cart.hook';
import { RecipientInformation } from './RecipientInformation';
import { SelectedGift } from './SelectedGift';
import './GiftsPurchase.scss';

const giftSchema = Yup.object().shape({
  recipient: Yup.object()
    .shape({
      first_name: Yup.string().required('This field is required'),
      last_name: Yup.string().required('This field is required'),
      date: Yup.date().required('This field is required'),
      email: Yup.string()
        .email('Email is invalid. Please enter a valid email address.')
        .required('This field is required'),
    })
    .required(),
  info: Yup.string(),
});

const initialValues = {
  recipient: {
    first_name: '',
    last_name: '',
    email: '',
    date: datesService.getTodayInSeattle(),
    phone: '',
  },
  info: '',
};

export const GiftPurchase = ({ gift, purchaseGiftInit, shopifyProduct, shopifyVariant, quantity }) => {
  const formRef = useRef();
  const shopifyCartAdd = useShopifyCartAdd();

  const onSubmit = useCallback(
    async data => {
      const today = datesService.getTodayInSeattle();
      const date = data.recipient.date.getTime() < today.getTime() ? today : data.recipient.date;

      const giftPurchase = await purchaseGiftInit({
        ...data,
        recipient: {
          ...data.recipient,
          date,
        },
        gift: gift._id,
        variantId: String(shopifyVariant.id),
        quantity,
      });

      if (giftPurchase?.payload?._id) {
        await shopifyCartAdd({
          items: [
            {
              quantity,
              id: shopifyVariant.id,
              properties: { Type: 'Gift', __giftPurchase: giftPurchase.payload._id },
            },
          ],
        });
      }

      return giftPurchase;
    },
    [purchaseGiftInit, gift, shopifyVariant, quantity, shopifyCartAdd]
  );

  const onSuccess = useCallback(() => {
    window.location.href = '/checkout';
  }, []);

  return (
    <>
      <SelectedGift gift={gift} shopifyProduct={shopifyProduct} shopifyVariant={shopifyVariant} quantity={quantity} />
      <Form
        innerRef={formRef}
        validationSchema={giftSchema}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <>
            <Row>
              <Col xs="12">
                <RecipientInformation />
                <Field name="token" type="hidden" />
              </Col>
            </Row>
            <div className="text-center">
              <SubmitButton color="primary" size={null} loadingText="Redirecting..." isSubmitting={isSubmitting}>
                Go to checkout
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

GiftPurchase.propTypes = {
  gift: PropTypes.object.isRequired,
  purchaseGiftInit: PropTypes.func.isRequired,
  shopifyProduct: PropTypes.object.isRequired,
  shopifyVariant: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
};

import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetGiftsTypes = createAction('Gifts', 'Get all');

export const getGiftsAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/gifts',
    method: 'GET',
    types: [GetGiftsTypes.REQUEST, GetGiftsTypes.SUCCESS, GetGiftsTypes.FAILURE],
  },
});

export const PurchaseGiftInitTypes = createAction('Gifts', 'Purchase Init');

export const purchaseGiftInitAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/gifts/purchase/init',
    method: 'POST',
    body: data,
    types: [PurchaseGiftInitTypes.REQUEST, PurchaseGiftInitTypes.SUCCESS, PurchaseGiftInitTypes.FAILURE],
  },
});

export const GetGiftPurchaseTypes = createAction('Gifts', 'Get Purchase');

export const getGiftPurchaseAction = code => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/gifts/purchase/${code}`,
    method: 'GET',
    types: [GetGiftPurchaseTypes.REQUEST, GetGiftPurchaseTypes.SUCCESS, GetGiftPurchaseTypes.FAILURE],
  },
});

export const GiftPurchaseGetStartedTypes = createAction('Gifts', 'Purchase Get Started');

export const giftPurchaseGetStartedAction = data => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/gifts/purchase/get-started`,
    method: 'POST',
    body: data,
    types: [
      GiftPurchaseGetStartedTypes.REQUEST,
      GiftPurchaseGetStartedTypes.SUCCESS,
      GiftPurchaseGetStartedTypes.FAILURE,
    ],
  },
});

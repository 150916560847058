import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { useQueryString } from '@bottomless/common/hooks';
import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { Heading } from '../../components/Heading';
import { useGifts } from './hooks';
import { GiftPurchase, Gifts } from './components';
import './GiftsPage.scss';

export const GiftsPage = () => {
  const { params, update } = useQueryString();
  const gifts = useGifts(params.giftId, params.variantId);

  const onChoose = useCallback(
    (giftId, variantId, quantity) => update({ giftId, variantId, ...(quantity ? { quantity } : {}) }),
    [update]
  );

  const heading = useMemo(() => {
    if (gifts.selectedGift) {
      return (
        <div className="d-flex align-items-center">
          <Link to="/gifts" title="Back" className="d-flex align-items-center gifts-back-link">
            <ArrowLeft />
          </Link>
          <span className="ml-1">Checkout</span>
        </div>
      );
    }

    return 'Gift Subscriptions';
  }, [gifts]);

  return (
    <PanelLayoutGuest>
      <Heading>{heading}</Heading>

      <DataHandler {...gifts} />
      <DataHandlerContent {...gifts}>
        {!gifts.selectedGift && <Gifts gifts={gifts.data} onChoose={onChoose} />}
        {gifts.selectedGift && (
          <GiftPurchase
            gift={gifts.selectedGift}
            purchaseGiftInit={gifts.purchaseGiftInit}
            shopifyProduct={gifts.selectedShopifyProduct}
            shopifyVariant={gifts.selectedShopifyVariant}
            quantity={Number(params.quantity || 1)}
          />
        )}
      </DataHandlerContent>
    </PanelLayoutGuest>
  );
};

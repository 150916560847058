import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import {
  AddressInput,
  Box,
  Form,
  GoogleMapsLoaded,
  GoogleMapsLoader,
  Heading,
  SubmitButton,
} from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';
import { GiftsDetails } from './GiftsDetails';

const Schema = Yup.object().shape({
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zip: Yup.string().required('This field is required'),
      state: Yup.string().required('This field is required'),
      street2: Yup.string(),
    })
    .required(),
});

export const RedeemGift = ({ giftPurchase, shopifyProduct, shopifyVariant, redeem }) => {
  const onSubmit = useCallback(
    data =>
      redeem({ ...data, first_name: giftPurchase.recipient.first_name, last_name: giftPurchase.recipient.last_name }),
    [giftPurchase, redeem]
  );

  return (
    <GoogleMapsLoader>
      <Heading>Hi {giftPurchase.recipient.first_name}!</Heading>
      <div className="mb-5 text-center">
        <div>
          Your great friend {giftPurchase.buyer.first_name} sent you a gift
          {giftPurchase.info ? ' with this note:' : '.'}
        </div>
        {giftPurchase.info && <div className="mt-4 font-italic">{giftPurchase.info}</div>}
      </div>
      <Row className="pt-3">
        <Col xs="12" md="6" className="mb-5">
          <GiftsDetails
            gift={giftPurchase.giftId}
            quantity={giftPurchase.quantity}
            shopifyProduct={shopifyProduct}
            shopifyVariant={shopifyVariant}
          />
        </Col>
        <Col xs="12" md="6">
          <PanelSection title="Enter delivery address">
            <Form validationSchema={Schema} onSubmit={onSubmit}>
              {({ isSubmitting, setFieldValue, values }) => (
                <>
                  <Box className="mb-4">
                    <GoogleMapsLoaded>
                      <AddressInput {...{ setFieldValue, values }} />
                    </GoogleMapsLoaded>
                  </Box>
                  <div className="text-center">
                    <SubmitButton isSubmitting={isSubmitting} size="default" className="px-5">
                      Redeem Gift
                    </SubmitButton>
                  </div>
                </>
              )}
            </Form>
          </PanelSection>
        </Col>
      </Row>
    </GoogleMapsLoader>
  );
};

RedeemGift.propTypes = {
  giftPurchase: PropTypes.shape({
    recipient: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }).isRequired,
    buyer: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
    }).isRequired,
    info: PropTypes.string,
    giftId: PropTypes.object.isRequired,
    shopifyProduct: PropTypes.object.isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  shopifyProduct: PropTypes.object.isRequired,
  shopifyVariant: PropTypes.object.isRequired,
  redeem: PropTypes.func.isRequired,
};

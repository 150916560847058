import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGiftPurchase } from './hooks';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import { RedeemGift } from './components';

export const GiftRedeemPage = ({ match }) => {
  const giftPurchase = useGiftPurchase(match.params.code);

  const onProfileRedirect = useCallback(() => {
    window.location.href = '/pages/subscription-by-usage#profile';
  }, []);

  return (
    <PanelLayoutGuest>
      <DataHandler {...giftPurchase} onProfileRedirect={onProfileRedirect} />
      <DataHandlerContent {...giftPurchase}>
        <RedeemGift
          giftPurchase={giftPurchase.data}
          shopifyProduct={giftPurchase.shopifyProduct}
          shopifyVariant={giftPurchase.shopifyVariant}
          redeem={giftPurchase.redeem}
        />
      </DataHandlerContent>
    </PanelLayoutGuest>
  );
};

GiftRedeemPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

import { useCallback } from 'react';

const useShopifyCall = endpoint => {
  return useCallback(
    async (method = 'GET', data) => {
      const response = await fetch(window.Shopify.routes.root + endpoint, {
        method,
        ...(data
          ? {
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            }
          : {}),
      });

      return response.json();
    },
    [endpoint]
  );
};

export const useShopifyCartAdd = () => {
  const shopifyCall = useShopifyCall('cart/add.js');

  return useCallback(data => shopifyCall('POST', data), [shopifyCall]);
};

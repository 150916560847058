import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Form, SubmitButton } from '@bottomless/common/components';
import { ProductOption } from './ProductOption';
import './ProductOptions.scss';

export const ProductOptions = ({ gift, onChoose }) => {
  const sizeOptionIndex = useMemo(
    () => gift.shopifyProduct.options.findIndex(option => option.toLowerCase().includes('size')),
    [gift]
  );

  const monthsOptionIndex = useMemo(
    () => gift.shopifyProduct.options.findIndex(option => option.toLowerCase().includes('month')),
    [gift]
  );

  const allDistinctOptions = useMemo(
    () =>
      gift.shopifyProduct.variants
        .reduce(
          (all, variant) => {
            variant.options.forEach((option, index) => all[index].values.add(option));

            return all;
          },
          gift.shopifyProduct.options.map((name, index) => ({ name, index, values: new Set() }))
        )
        .map(({ values, ...rest }) => ({ ...rest, values: Array.from(values.values()) })),
    [gift]
  );

  const sizeOption = useMemo(() => (sizeOptionIndex !== -1 ? allDistinctOptions[sizeOptionIndex].values[0] : null), [
    sizeOptionIndex,
    allDistinctOptions,
  ]);

  const monthsOptions = useMemo(() => (monthsOptionIndex !== -1 ? allDistinctOptions[monthsOptionIndex] : null), [
    monthsOptionIndex,
    allDistinctOptions,
  ]);

  const choosableOptions = useMemo(
    () => allDistinctOptions.filter((_, i) => i !== sizeOptionIndex && i !== monthsOptionIndex),
    [allDistinctOptions, sizeOptionIndex, monthsOptionIndex]
  );

  const availableOption = useMemo(
    () => gift.gifts.find(singleGift => !singleGift.limit || singleGift.purchaseCounter < singleGift.limit)?._id,
    [gift]
  );

  const allowQuantity = useMemo(() => gift.gifts[0].allowQuantity, [gift]);

  const initialValues = useMemo(
    () => ({
      gift: availableOption,
      ...choosableOptions.reduce((all, option) => ({ ...all, [`option-${option.index}`]: option.values[0] }), {}),
      ...(allowQuantity ? { quantity: 1 } : {}),
    }),
    [availableOption, choosableOptions, allowQuantity]
  );

  const getShopifyVariant = useCallback(
    (chosenGift, values) => {
      // eslint-disable-next-line no-unused-vars
      const { gift: giftId, quantity, ...data } = values;

      const options = Object.values(data);

      if (sizeOptionIndex !== -1) {
        options.splice(sizeOptionIndex, 0, sizeOption);
      }

      if (monthsOptionIndex !== -1) {
        const monthsOption = monthsOptions.values.find(value => value.includes(chosenGift.credits));
        options.splice(monthsOptionIndex, 0, monthsOption);
      }

      const optionsString = options.join(' / ');

      return gift.shopifyProduct.variants.find(
        variant => variant.public_title === optionsString || variant.title === optionsString
      );
    },
    [gift, monthsOptionIndex, sizeOptionIndex, monthsOptions, sizeOption]
  );

  const onSubmit = useCallback(
    ({ gift: giftId, quantity, ...data }) => {
      const chosenGift = gift.gifts.find(g => g._id === giftId);
      const variantId = getShopifyVariant(chosenGift, data)?.id;

      onChoose(giftId, variantId, quantity);
    },
    [gift, onChoose, getShopifyVariant]
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} className="text-left flex-grow-1 d-flex flex-column">
      {({ isSubmitting, values }) => (
        <>
          {choosableOptions.map(option => (
            <div key={option.name} className={classNames({ 'd-none': option.name === 'Title' })}>
              <Field
                label={option.name}
                name={`option-${option.index}`}
                type="select"
                options={option.values.reduce((all, value) => ({ ...all, [value]: value }), {})}
              />
            </div>
          ))}
          {allowQuantity && (
            <Field label="Quantity" name="quantity" type="number" min="1" disabled={!availableOption} />
          )}
          <div className="gifts-options">
            {gift.gifts.map(singleGift => (
              <ProductOption
                key={singleGift._id}
                gift={singleGift}
                shopifyVariant={getShopifyVariant(singleGift, values)}
              />
            ))}
          </div>

          <SubmitButton
            color="primary"
            size="default"
            block
            isSubmitting={isSubmitting}
            className="mt-auto"
            disabled={!availableOption}
          >
            {availableOption ? 'Select' : 'Sold Out'}
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

ProductOptions.propTypes = {
  onChoose: PropTypes.func.isRequired,
  gift: PropTypes.shape({
    gifts: PropTypes.array,
    shopifyProduct: PropTypes.object.isRequired,
  }),
};
